<template>
  <v-container v-if="user" fluid>
    <v-card>
      <v-card-title class="teal lighten-3 accent-4 white--text">
        Mis Rutas
        <v-spacer />
      </v-card-title>

      <v-row class="mx-2 mt-2">
        <v-col cols="12" md="4">
          <v-select
            :items="dias"
            v-model="searchDia"
            label="Filtrar por día"
          ></v-select>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            v-model="search"
            @input="filtrarItems"
            append-icon="mdi-magnify"
            label="Búsqueda"
            single-line
            hide-details
          ></v-text-field>
        </v-col>

        <v-col v-if="esAdmid || esSupervisor" cols="12" md="4">
          <v-autocomplete
            label="Clientes del usuario"
            v-model="clienteSelected"
            @input="filtrarPorUsuario"
            :items="itemsUsuarios"
            item-text="nombre"
            item-value="id"
            clearable
            @change="onUsuarioSeleccionado(clienteSelected)"
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="itemsModificados"
        :loading="loading"
        loading-text="Cargando..."
        no-data-text="No se encontraron clientes"
        no-results-text="No se encontraron clientes"
        :footer-props="{
          'items-per-page-text': 'Clientes por página',
        }"
        :header-props="{
          'sort-by-text': 'Ordenar por',
        }"
      >
        <!-- Columna acciones -->
        <template v-slot:[`item.acciones`]="{ item }">
          <AccionesMisClientes
            :item="item"
            @clienteModificado="clienteModificado"
          />
        </template>

        <template v-slot:[`item.ruta`]="{ item }">
          <v-btn
            icon
            :color="item.sinLocalizacion ? 'red' : 'green'"
            @click="
              item.sinLocalizacion ? mostrarAlerta(item) : mostrarRuta(item)
            "
          >
            <v-icon>{{
              item.sinLocalizacion ? "mdi-close-circle" : "mdi-truck-delivery"
            }}</v-icon>
          </v-btn>
        </template>

        <template v-slot:[`item.facturas_pendientes`]="{ item }">
          <v-btn
            v-if="item.tieneFacturasPendientes"
            icon
            :color="'green'"
            @click="abrirDialog(item)"
            class="factura-pendiente-btn"
          >
            <v-icon> mdi-file-document-check </v-icon>
          </v-btn>
          <span v-else :color="'red'">No hay facturas pendientes</span>
        </template>
      </v-data-table>
      <!-- Diálogo para "Ver Facturas Pendientes" -->
      <v-dialog
        fullscreen
        transition="dialog-bottom-transition"
        v-model="dialog"
      >
        <FacturaPendiente
          v-if="clienteActual"
          :item="clienteActual"
          @cerrarDialog="cerrarDialog"
        />
      </v-dialog>

      <v-dialog v-model="dialogVisible" max-width="600px">
        <v-card>
          <v-card-title
            >Selecciona una localización para comenzar la ruta</v-card-title
          >
          <v-card-text>
            <v-data-table
              :headers="headersLocalizaciones"
              :items="localizaciones"
              hide-default-footer
            >
              <!-- Slot para la fila completa -->
              <template v-slot:[`item`]="{ item }">
                <tr
                  @click="seleccionarLocalizacion(item)"
                  style="cursor: pointer"
                >
                  <td>{{ item.descripcion }}</td>
                  <td>{{ item.direccion_linea_1 }}</td>
                  <td>
                    <v-btn
                      icon
                      color="primary"
                      @click.stop="seleccionarLocalizacion(item)"
                    >
                      <v-icon>mdi-map-marker</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="primary" @click="dialogVisible = false"
              >Cancelar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import AccionesMisClientes from "@/components/AccionesMisClientes.vue";
import FacturaPendiente from "@/components/FacturaPendiente.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      clientesSinLocalizacion: [],
      dialogVisible: false,
      selectedLocalizacion: [],
      localizaciones: [],
      dialog: false,
      clienteActual: null,
      search: "",
      searchDia: "",
      clientesFiltrados: false,
      items: [],
      itemsTodosClientes: [],
      itemsFiltrados: [],
      itemsUsuarios: [],
      dias: [
        { text: "Todos", value: "" },
        { text: "Lunes", value: "LUNES" },
        { text: "Martes", value: "MARTES" },
        { text: "Miercoles", value: "MIERCOLES" },
        { text: "Jueves", value: "JUEVES" },
        { text: "Viernes", value: "VIERNES" },
        { text: "Sabado", value: "SABADO" },
        { text: "Domingo", value: "DOMINGO" },
      ],
      headers: [
        {
          text: "Código",
          value: "codigo",
        },
        {
          text: "Nombre",
          value: "razon_social",
        },
        {
          text: "Día",
          value: "dia",
        },
        {
          text: "Ruta",
          value: "ruta",
          sortable: false,
        },
        {
          text: "Facturas Pendientes",
          value: "facturas_pendientes",
          sortable: false,
        },
      ],
      headersLocalizaciones: [
        {
          text: "Descripción",
          value: "descripcion",
        },
        {
          text: "Dirección",
          value: "direccion_linea_1",
        },
        {
          text: "",
          value: "abrir",
          sortable: false,
        },
      ],
      esAdmid: false,
      esSupervisor: false,
      loading: true,
      clienteSelected: "",
      usuarioId: "",
      facturasPendientes: [],
      sucursalSelected: null,
    };
  },
  methods: {
    async mostrarRuta(item) {
      try {
        // Hacer un GET para obtener las localizaciones del cliente
        const response = await axios.get(
          `/clientes/get_localizaciones?id_cliente=${item.id}`
        );
        this.localizaciones = response.data;

        if (this.localizaciones.length > 1) {
          // Si hay más de una localización, mostrar el diálogo
          this.dialogVisible = true;
        } else if (this.localizaciones.length === 1) {
          // Si solo hay una localización, abrir el mapa directamente
          const { latitud, longitud } = this.localizaciones[0];

          const confirmarIncio = confirm(
            `${item.razon_social} tiene una sola localización. ¿Desea iniciar su ruta?`
          );
          if (confirmarIncio) {
            this.abrirMapa(latitud, longitud);
          } else {
            console.log("El usuario cancelo la opracion");
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          alert(
            `El cliente ${item.razon_social} no tiene localización registrada.`
          );
        }
        console.error("Error al obtener las localizaciones:", error);
      }
    },
    abrirMapa(latitud, longitud) {
      if (latitud && longitud) {
        const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${latitud},${longitud}`;
        window.open(googleMapsUrl, "_blank");
        this.dialogVisible = false; // Cerrar el diálogo después de abrir el mapa
      }
    },

    seleccionarLocalizacion(item) {
      console.log("Seleccionando localización:", item);
      this.selectedLocalizacion = item;
      if (item.latitud && item.longitud) {
        // Llamamos a abrirMapa con las coordenadas
        this.abrirMapa(item.latitud, item.longitud);
        // Cerramos el diálogo
        this.dialogVisible = false;
      } else {
        console.error("No se ha seleccionado ninguna localización");
      }
    },

    mostrarAlerta(item) {
      if (!item.latitud || !item.longitud) {
        alert(
          `El cliente ${item.razon_social} no tiene localización registrada.`
        );
      }
    },

    abrirDialog(item) {
      this.clienteActual = item;
      this.dialog = true;
    },

    cerrarDialog(dialog) {
      this.dialog = dialog;
      this.clienteActual = null;
    },

    async obtenerClientesSinLocalizacion() {
      try {
        const response = await axios.get("/clientes/clientes_sin_localizacion");
        this.clientesSinLocalizacion = response.data;
        console.log(this.clientesSinLocalizacion);
      } catch (error) {
        console.error(error);
      }
    },

    async clienteModificado() {
      if (this.esAdmid) {
        this.clienteSelected = null;
        const response = await axios.get("/usuarios/get_usuarios");
        this.itemsUsuarios = response.data.usuarios;
        // console.log(this.itemsUsuarios);

        const response2 = await axios.get("/clientes/get");
        this.itemsTodosClientes = response2.data;
        this.items = this.itemsTodosClientes;
        this.itemsFiltrados = this.itemsTodosClientes;
      } else {
        //El usuario no es admin, entonces debe obtener el array solo de sus clientes
        const response2 = await axios.get(
          `/clientes/get_mis_clientes?vendedor_id=${this.usuarioId}`
        );
        this.items = response2.data;
        this.itemsFiltrados = this.items;
      }
    },

    async filtrarPorUsuario() {
      if (this.clienteSelected) {
        const response = await axios.get(
          `/clientes/get_mis_clientes?vendedor_id=${this.clienteSelected}`
        );
        this.itemsFiltrados = response.data;
        this.items = response.data;
      } else {
        this.itemsFiltrados = this.itemsTodosClientes;
        this.items = this.itemsTodosClientes;
      }
    },
    filtrarItems() {
      const criterioBusqueda = this.search.toLowerCase();

      this.itemsFiltrados = this.items.filter((item) => {
        // Aplica aquí tu lógica de filtrado según tus necesidades
        for (let prop in item) {
          const valor = item[prop];

          if (
            typeof valor === "string" &&
            valor.toLowerCase().includes(criterioBusqueda)
          ) {
            return true; // Si alguna propiedad coincide, se incluye el item en los resultados
          } else if (
            typeof valor === "number" &&
            valor.toString().toLowerCase().includes(criterioBusqueda)
          ) {
            return true; // Si el valor numérico coincide, se incluye el item en los resultados
          }
        }
        return false;
      });
    },
    async obtenerRol() {
      const response = await axios.get("/usuarios/get_secciones");
      const secciones = response.data.secciones;
      secciones.forEach((element) => {
        if (element === "ADMINISTRADOR") this.esAdmid = true;
        if (element === "supervisor_vendedores") this.esSupervisor = true;
      });
      if (this.esAdmid) {
        //Si el usuario es admin obtiene un array con todos los vendedores para poder visualizar sus clientes
        const response = await axios.get("/usuarios/get_usuarios");
        // this.itemsUsuarios = response.data.usuarios;
        this.itemsUsuarios = response.data.usuarios.filter(
          (usuario) => usuario.nombre_adminpaq
        );
        // console.log(this.itemsUsuarios);

        const response2 = await axios.get("/clientes/get");
        this.itemsTodosClientes = response2.data;
        this.items = this.itemsTodosClientes;
        this.itemsFiltrados = this.itemsTodosClientes;
      } else if (this.esSupervisor) {
        //Si el usuario es supervisor obtiene un array con los vendedores de las sucursales que supervisa para poder visualizar sus clientes
        const response = await axios.get("/usuarios/yo");

        const permisosArray = response.data.permisos.split(" ");
        const municipiosSet = new Set();

        permisosArray.forEach((permiso) => {
          //Primero se obtienen los nombres de las sucursales que supervisa
          const partes = permiso.split(":");
          if (
            partes.length === 3 &&
            partes[1] === "supervisor_vendedores" &&
            partes[2] === "ver"
          ) {
            municipiosSet.add(partes[0]);
          }
        });

        const municipiosConPermisos = Array.from(municipiosSet); //Array con nombres de las sucursales que supervisa

        const response2 = await axios.get("/usuarios/get_usuarios");
        const usuariosVendedores = response2.data.usuarios.filter(
          (usuario) => usuario.nombre_adminpaq
        );
        this.itemsUsuarios = usuariosVendedores.filter(
          (usuario) => municipiosConPermisos.includes(usuario.sucursal) //Usuarios de sucursales que supervisa
        );
        // console.log(this.itemsUsuarios);

        let todosClientes = []; //Array con los clientes de todos los usuarios
        this.itemsUsuarios.forEach(async (usuario) => {
          const response3 = await axios.get(
            `/clientes/get_mis_clientes?vendedor_id=${usuario.id}`
          );
          todosClientes.push(...response3.data);
        });
        // console.log(todosClientes);

        this.itemsTodosClientes = todosClientes;
        this.items = this.itemsTodosClientes;
        this.itemsFiltrados = this.itemsTodosClientes;
      } else {
        //El usuario no es admin ni supervisor, entonces debe obtener el array solo de sus clientes
        const response = await axios.get("/usuarios/yo"); //Se obtienen datos del usuario, en este caso requerimos su id
        this.usuarioId = response.data.id;
        const response2 = await axios.get(
          `/clientes/get_mis_clientes?vendedor_id=${this.usuarioId}`
        );
        this.items = response2.data;
        this.itemsFiltrados = this.items;
      }
      this.loading = false;
    },
    getColor(estado) {
      return estado ? "green" : "warning";
    },
    getColorEstado(estado) {
      return estado ? "green" : "error";
    },

    async obtenerFacturasPendientes() {
      try {
        let sucursal = this.sucursalSelected;

        if (!sucursal) {
          const response = await axios.get("/usuarios/yo");
          sucursal = response.data.sucursal;
        }

        console.log("Sucursal usada para facturas pendientes:", sucursal);

        const response = await axios.get(
          `/clientes/get_clientes_con_pendientes?sucursal=${sucursal}`
        );
        this.facturasPendientes = response.data;
        console.log("Facturas pendientes", this.facturasPendientes);
      } catch (error) {
        console.error(error);
      }
    },

    async onUsuarioSeleccionado(usuarioId) {
      const usuarioSeleccionado = this.itemsUsuarios.find(
        (usuario) => usuario.id === usuarioId
      );

      if (usuarioSeleccionado) {
        this.sucursalSelected = usuarioSeleccionado.sucursal;
        console.log("Sucursal seleccionada:", this.sucursalSelected);
      } else {
        this.sucursalSelected = null;
        console.log("No se seleccionó un usuario válido.");
      }
      await this.obtenerFacturasPendientes();
    },
  },
  created() {
    this.obtenerRol();
  },
  components: {
    AccionesMisClientes,
    FacturaPendiente,
  },
  computed: {
    ...mapGetters(["user"]),
    itemsModificados() {
      //clientes modificados para mostrarlos en la tabla
      let itemsModificados = [];
      if (this.searchDia === "") {
        itemsModificados = this.itemsFiltrados.map((cliente) => {
          const nuevoCliente = { ...cliente };
          nuevoCliente.sinLocalizacion = this.clientesSinLocalizacion.includes(
            cliente.id
          );

          nuevoCliente.tieneFacturasPendientes =
            this.facturasPendientes.includes(cliente.codigo);

          return nuevoCliente;
        });
      } else {
        itemsModificados = this.itemsFiltrados
          .filter((cliente) => cliente.dia === this.searchDia)
          .map((cliente) => {
            const nuevoCliente = { ...cliente };
            nuevoCliente.sinLocalizacion =
              this.clientesSinLocalizacion.includes(cliente.id);

            nuevoCliente.tieneFacturasPendientes =
              this.facturasPendientes.includes(cliente.codigo);

            return nuevoCliente;
          });
      }

      console.log(itemsModificados);
      return itemsModificados;
    },
  },
  mounted() {
    this.obtenerClientesSinLocalizacion();
    this.obtenerFacturasPendientes();
  },
};
</script>
