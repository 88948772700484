<template>
  <v-card>
    <v-btn
      class="absolute top right"
      icon
      color="blue darken-1"
      text
      @click="cerrar()"
    >
      <v-icon dark> mdi-close </v-icon>
    </v-btn>
    <v-card-title>Facturas pendientes</v-card-title>
    <v-card-text>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              >Pendiente total: ${{
                formatoPrecio(estadoCredito.pendiente_total)
              }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Crédito activo:
              <v-icon
                class="mr-2"
                :color="estadoCredito.credito_activado ? 'green' : 'red'"
                size="25"
              >
                {{
                  estadoCredito.credito_activado
                    ? "mdi-check-circle"
                    : "mdi-close-circle"
                }}
              </v-icon>
              <!-- {{
                estadoCredito.credito_activado ? "Sí" : "No"
              }} -->
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-btn class="mt-3 mb-3" color="primary" @click="verEstadoCuenta = true"
          >Ver Estado de cuenta</v-btn
        >
      </v-list>

      <v-data-table
        dense
        :headers="headers"
        :items="itemsFormat"
        :items-per-page="15"
        class="elevation-1"
        no-data-text="No hay elementos para mostrar"
        :footer-props="{
          'items-per-page-text': 'Elementos por página',
        }"
        :header-props="{
          'sort-by-text': 'Ordenar por',
        }"
      >
        <!-- Columna de Fecha -->
        <template v-slot:[`item.fecha`]="{ item }">
          {{ getFechaLegible(item.fecha) }}
        </template>
        <!-- Columna de Fecha vencimiento -->
        <template v-slot:[`item.fecha_vencimiento`]="{ item }">
          {{ getFechaLegible(item.fecha_vencimiento) }}
        </template>
        <!-- Columna de total -->
        <template v-slot:[`item.total`]="{ item }">
          {{ "$" + formatoPrecio(item.total) }}
        </template>
        <!-- Columna de Pendiente -->
        <template v-slot:[`item.pendiente`]="{ item }">
          {{ "$" + formatoPrecio(item.pendiente) }}
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="verEstadoCuenta" max-width="500px">
      <v-card>
        <v-card-title>Estado de cuenta de {{ item.razon_social }}</v-card-title>
        <v-card-text>
          <v-alert type="info" text color="info" dark
            >Selecciona un correo para enviar el estado de cuenta o crea uno
            nuevo, despues seleccionalo de la lista.</v-alert
          >
          <v-data-table
            dense
            :headers="headersCorreo"
            :items="itemsCorreo"
            no-data-text="No hay correos para mostrar"
            hide-default-footer
            class="mb-7"
            @click:row="enviarCorreo"
          >
            <template v-slot:[`item.enviar`]="{ item }">
              <v-btn
                icon
                color="primary"
                :loading="loading && loadingRow === item.id"
                :disabled="loading && loadingRow === item.id"
                @click="enviarCorreo(item)"
                ><v-icon>mdi-email-arrow-right</v-icon></v-btn
              >
            </template>
            <template v-slot:[`item.email`]="{ item }">
              <div
                :class="{ 'loading-row': loading && loadingRow === item.id }"
              >
                {{ item.email }}
              </div>
            </template>
          </v-data-table>
          <v-form ref="form" v-model="formValido" lazy-validation>
            <p>
              ¿Quieres crear un nuevo correo para {{ item.razon_social }}?
              <br />Hazlo aqui:
            </p>
            <v-text-field
              v-model="email"
              outlined
              :rules="[emailRules]"
              label="Correo electrónico"
              required
            ></v-text-field>
            <v-btn small @click="CrearCorreo()">Crear Correo</v-btn>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="mt-2"
            color="blue darken-1"
            text
            @click="verEstadoCuenta = false"
          >
            Cerrar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="mt-2" color="primary" text @click="descargarPDF()">
            Descargar PDF</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  name: "FacturaPendiente",
  props: ["item"],
  data() {
    return {
      email: "",
      loading: false,
      loadingRow: null,
      formValido: false,
      verEstadoCuenta: false,
      items: [],
      itemsCorreo: [],
      estadoCredito: {},
      saldo_pendiente: 0,
      headers: [
        {
          text: "Concepto",
          value: "concepto",
        },
        {
          text: "Serie y Folio",
          value: "serie_folio",
        },
        {
          text: "Fecha",
          value: "fecha",
          // sortable: false,
        },
        {
          text: "Fecha vencimiento",
          value: "fecha_vencimiento",
          // sortable: false,
        },
        {
          text: "Dias restantes",
          value: "dias_restantes",
        },
        {
          text: "Total",
          value: "total",
        },
        {
          text: "Pendiente",
          value: "pendiente",
        },
      ],
      headersCorreo: [
        {
          text: "Correo",
          value: "email",
        },
        {
          text: "Enviar",
          value: "enviar",
        },
      ],
    };
  },
  methods: {
    cerrar() {
      this.$emit("cerrarDialog", (this.dialog = false));
    },
    async getEstadoCrediticio() {
      try {
        const response = await axios.get(
          `/clientes/pendiente?id_cliente=${this.item.id}`
        );
        console.log(response.data);
        // this.estadoCredito = response.data.estado_credito;
        this.items = response.data.documentos;
        let totalDeuda = this.items.reduce(
          (acumulador, item) => acumulador + item.pendiente,
          0
        );
        this.estadoCredito = {
          credito_activado: response.data.estado_credito.credito_activado,
          pendiente_total: totalDeuda,
        };
      } catch (error) {
        // alert(`Error: ${JSON.stringify(error.response.data.detail)}`);
        console.log(error.response.data);
      }
    },
    getFechaLegible(fechaLarga) {
      const fecha = new Date(fechaLarga);

      const año = fecha.getFullYear();
      const mes = fecha.getMonth() + 1;
      const dia = fecha.getDate();
      // const hora = fecha.getHours();
      // const minutos = fecha.getMinutes().toString().padStart(2, "0");

      const fechaLegible = `${dia}/${mes}/${año}`;
      // const horaLegible = `${hora}:${minutos}`;

      return `${fechaLegible}`;
    },
    formatoPrecio(precioProducto) {
      // Formatear el número utilizando una función personalizada
      const numero = parseFloat(precioProducto);
      // console.log(numero);
      let precio = 0;

      precio = numero.toFixed(2); // Limitar a 2 decimales
      const separadorDecimal = precio.indexOf(".") !== -1 ? "." : "";
      const [parteEntera, parteDecimal] = precio.split(separadorDecimal);

      // Formatear la parte entera con separador de miles
      const parteEnteraFormateada = parteEntera.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      return `${parteEnteraFormateada}${separadorDecimal}${parteDecimal}`;
    },
    calcularDiasRestantes(fecha) {
      const fechaVencimiento = new Date(fecha);
      const fechaActual = new Date();

      // Calcula la diferencia en milisegundos
      const diferenciaMs = fechaVencimiento - fechaActual;

      // Convierte la diferencia en días
      const diasRestantes = Math.ceil(diferenciaMs / (1000 * 60 * 60 * 24));

      return diasRestantes;
    },

    async CrearCorreo() {
      try {
        const datos = {
          cliente: this.item.id,
          email: this.email,
        };

        const response = await axios.post(`/clientes/crear_email`, datos);
        console.log("Datos recibidos", response.data);
        alert("Correo creado exitosamente");

        this.itemsCorreo.push(response.data);
        await this.getCorreos();
        this.email = "";
        this.emailRules = true;
      } catch (error) {
        // alert(`Error: ${JSON.stringify(error.response.data.detail)}`);
        console.log(error.response.data);
      }
    },

    async getCorreos() {
      try {
        const response = await axios.get(
          `/clientes/get_emails?id_cliente=${this.item.id}`
        );
        this.itemsCorreo = response.data;

        console.log(response.data);
      } catch (error) {
        // alert(`Error: ${JSON.stringify(error.response.data.detail)}`);
        console.log(error.response.data);
      }
    },

    async descargarPDF() {
      //descargar pdf por medio de un get
      try {
        // Realiza la solicitud GET al backend
        const response = await axios.get(
          `/pdfs/estado_de_cuenta/${this.item.id}`,
          {
            responseType: "blob", // Indica que esperas un archivo binario
          }
        );

        // Crear una URL para el archivo descargado
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Crear un elemento <a> dinámico para forzar la descarga
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Estado_de_cuenta_${this.item.razon_social}.pdf`
        ); // Nombre del archivo
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Opcional: Revocar la URL después de usarla
        window.URL.revokeObjectURL(url);
      } catch (error) {
        if (error.response.status === 404) {
          alert("Este cliente no tiene estado de cuenta.");
        } else {
          console.error("Error al descargar el archivo:", error);
          alert("Hubo un problema al intentar descargar el archivo.");
        }
      }
    },

    async enviarCorreo(item) {
      if (this.loading && this.loadingRow === item.id) return;

      this.loading = true;
      this.loadingRow = item.id;

      try {
        const response = await axios.get(
          `/pdfs/enviar_estado_de_cuenta_por_correo/${item.id}`
        );
        console.log(response.data);
        alert("Estado de cuenta enviado exitosamente");
      } catch (error) {
        console.log(error.response.data);
        alert("El cliente no tiene estado de cuenta para enviar.");
      } finally {
        this.loading = false;
        this.loadingRow = null;
      }
    },

    emailRules(value) {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },
  },
  computed: {
    itemsFormat() {
      return this.items.map((documento) => {
        const nuevoDocumento = { ...documento };
        // nuevoDocumento.fecha = this.getFechaLegible(documento.fecha);
        // nuevoDocumento.fecha_vencimiento = this.getFechaLegible(
        //   documento.fecha_vencimiento
        // );
        nuevoDocumento.dias_restantes = this.calcularDiasRestantes(
          documento.fecha_vencimiento
        );
        nuevoDocumento.serie_folio = `${documento.serie} ${documento.folio}`;
        return nuevoDocumento;
      });
    },
  },
  async created() {
    this.getEstadoCrediticio();
    this.getCorreos();
  },
};
</script>

<style>
.loading-row {
  background-color: rgba(0, 0, 255, 0.1); /* Color de fondo */
  position: relative;
}

.loading-row::after {
  content: "";
  display: block;
  width: 100%;
  height: 4px;
  background-color: blue;
  position: absolute;
  bottom: 0;
  left: 0;
  animation: loading-bar 0.4s linear infinite;
}

@keyframes loading-bar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.close-btn {
  float: right;
}

.absolute {
  position: absolute;
}

.top {
  top: 10;
}

.right {
  right: 10;
}
</style>
