<template>
  <div>
    <h1 v-if="!$route.params.token" class="text-center teal--text">
      Subscripción Cancelada
    </h1>
    <h1 v-else class="text-center teal--text">
      Subscripción Cancelada con exito
    </h1>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SubscripcionCancelada",
  data() {
    return {};
  },
  methods: {
    async validarToken() {
      // Capturar el token de los parámetros de la ruta
      const token = this.$route.params.token;

      if (!token) {
        alert("Token no encontrado en la URL.");
        return;
      }

      try {
        // Enviar el token al backend utilizando Axios
        const response = await axios.post(
          `/clientes/cancelar_subscripcion/${token}`
        );

        // Manejar respuesta exitosa
        alert(response.ok || "Suscripción cancelada con éxito.");
      } catch (error) {
        // Manejar errores
        if (error.response) {
          // Respuesta del backend con error
          alert(
            error.response.data.message || "Token inválido o ya utilizado."
          );
        } else {
          // Error de red u otro problema
          console.error("Error:", error);
          alert("Hubo un problema al procesar tu solicitud.");
        }
      }
    },
  },
  computed: {},
  async created() {},
  mounted() {
    this.validarToken();
  },
  watch: {},
};
</script>
