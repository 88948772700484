<template>
  <v-card>
    <v-btn
      class="absolute top right"
      icon
      color="blue darken-1"
      text
      @click="cerrar()"
    >
      <v-icon dark> mdi-close </v-icon>
    </v-btn>
    <v-card-title>Subscripciones de: {{ item.razon_social }}</v-card-title>
    <v-card-text>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              ><v-btn color="primary" @click="dialogCrearCorreo = true"
                >Crear Correo</v-btn
              ></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-data-table
        dense
        :headers="headers"
        :items="items"
        :items-per-page="5"
        class="elevation-1"
        disable-sort
        no-data-text="No hay elementos para mostrar"
        :footer-props="{
          'items-per-page-text': 'Elementos por página',
        }"
        :header-props="{
          'sort-by-text': 'Ordenar por',
        }"
      >
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id }}
        </template>

        <template v-slot:[`item.email`]="{ item }">
          {{ item.email }}
        </template>

        <template v-slot:[`item.activo`]="{ item }">
          <v-switch
            v-model="item.activo"
            :key="item.id + 'activo'"
            @change="modificarSwitch(item, 'activo')"
          >
            {{ item.activo }}
          </v-switch>
        </template>

        <template v-slot:[`item.avisos`]="{ item }">
          <v-switch
            v-model="item.avisos"
            :key="item.id + 'avisos'"
            color="primary"
            @change="modificarSwitch(item, 'avisos')"
          >
            {{ item.avisos }}
          </v-switch>
        </template>

        <template v-slot:[`item.publicidad`]="{ item }">
          <v-switch
            v-model="item.publicidad"
            :key="item.id + 'publicidad'"
            @change="modificarSwitch(item, 'publicidad')"
            >{{ item.publicidad }}</v-switch
          >
        </template>
        <template v-slot:[`item.principal`]="{ item }">
          <v-switch
            v-model="item.principal"
            :key="item.id + 'principal'"
            @change="modificarSwitch(item, 'principal')"
            >{{ item.principal }}</v-switch
          >
        </template>
        <template v-slot:[`item.subscripcion_cancelada`]="{ item }">
          <!--  poner texto en verde que diga Activa si es true -->
          <span
            :class="{
              'texto-verde': !item.subscripcion_cancelada,
              'texto-rojo': item.subscripcion_cancelada,
            }"
          >
            {{ !item.subscripcion_cancelada ? "Activa" : "Cancelada" }}</span
          >
        </template>
        <template v-slot:[`item.fecha_creacion`]="{ item }">
          {{ formatoFecha(item.fecha_creacion) }}
        </template>
      </v-data-table>
    </v-card-text>

    <!-- Diálogo de creación de correos -->
    <v-dialog v-model="dialogCrearCorreo" max-width="600px">
      <v-card>
        <v-card-title class="text"
          >Crear correo de: {{ item.razon_social }}</v-card-title
        >

        <v-card-text>
          <v-form ref="form" v-model="formValido" lazy-validation>
            <v-text-field
              v-model="email"
              outlined
              :rules="[emailRules]"
              label="Correo electrónico"
              required
            ></v-text-field>
            <v-checkbox
              v-model="publicidad"
              label="Publicidad"
              required
            ></v-checkbox>
            <v-checkbox v-model="avisos" label="Avisos" required></v-checkbox>
            <v-checkbox
              v-model="principal"
              label="Principal"
              required
            ></v-checkbox>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cerrar2()">Cancelar</v-btn>
          <v-btn color="blue darken-1" text @click="crearCorreo()">
            Crear
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: "FacturaPendiente",
  props: ["item"],
  data() {
    return {
      email: "",
      publicidad: false,
      avisos: false,
      principal: false,
      formValido: false,
      dialogCrearCorreo: false,
      items: [],
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Activo",
          value: "activo",
          sortable: false,
        },
        {
          text: "Avisos",
          value: "avisos",
          sortable: false,
        },
        {
          text: "Publicidad",
          value: "publicidad",
          sortable: false,
        },
        {
          text: "Principal",
          value: "principal",
          sortable: false,
        },
        {
          text: "Subscripción",
          value: "subscripcion_cancelada",
          sortable: false,
        },
        {
          text: "Fecha de Creación",
          value: "fecha_creacion",
        },
      ],
    };
  },
  methods: {
    cerrar() {
      this.$emit("cerrarDialog", (this.dialog = false));
    },

    cerrar2() {
      this.dialogCrearCorreo = false;
    },

    async getCorreos() {
      try {
        const response = await axios.get(
          `/clientes/get_emails?id_cliente=${this.item.id}`
        );
        this.items = response.data;
        console.log(response.data);
      } catch (error) {
        // alert(`Error: ${JSON.stringify(error.response.data.detail)}`);
        console.log(error.response.data);
      }
    },

    async crearCorreo() {
      try {
        const datos = {
          cliente: this.item.id,
          email: this.email,
          publicidad: this.publicidad,
          avisos: this.avisos,
          principal: this.principal,
        };

        const response = await axios.post(`/clientes/crear_email`, datos);
        console.log("Datos recibidos", response.data);

        this.items.push(response.data);
        await this.getCorreos();
        this.resetForm();
        this.dialogCrearCorreo = false;
      } catch (error) {
        // alert(`Error: ${JSON.stringify(error.response.data.detail)}`);
        console.log(error.response.data);
      }
    },

    resetForm() {
      this.email = "";
      this.publicidad = false;
      this.avisos = false;
      this.principal = false;
    },

    async modificarSwitch(item, propiedad) {
      try {
        const payload = {
          id: item.id,
          [propiedad]: item[propiedad],
        };

        const response = await axios.put(`/clientes/modificar_email`, payload);
        console.log("Actualizacion exitosa", response.data);
        //await this.getCorreos();
      } catch (error) {
        // alert(`Error: ${JSON.stringify(error.response.data.detail)}`);
        console.log(error.response.data);
        item[propiedad] = !item[propiedad];
      }
    },

    formatoFecha(fecha) {
      //formatear fecha con horas y minutos
      const date = new Date(fecha);
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return date.toLocaleString("es-MX", options);
    },

    emailRules(value) {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },

    defaultColor(item) {
      return item.subscripcion_cancelada === true
        ? "texto-verde"
        : "texto-rojo";
    },
  },
  computed: {},
  async created() {
    this.getCorreos();
  },
  mounted() {},
  watch: {},
};
</script>

<style>
.close-btn {
  float: right;
}

.absolute {
  position: absolute;
}

.top {
  top: 10;
}

.right {
  right: 10;
}
.texto-verde {
  color: green;
}

.texto-rojo {
  color: red;
}
</style>
