var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-btn',{staticClass:"absolute top right",attrs:{"icon":"","color":"blue darken-1","text":""},on:{"click":function($event){return _vm.cerrar()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close ")])],1),_c('v-card-title',[_vm._v("Subscripciones de: "+_vm._s(_vm.item.razon_social))]),_c('v-card-text',[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialogCrearCorreo = true}}},[_vm._v("Crear Correo")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"items-per-page":5,"disable-sort":"","no-data-text":"No hay elementos para mostrar","footer-props":{
        'items-per-page-text': 'Elementos por página',
      },"header-props":{
        'sort-by-text': 'Ordenar por',
      }},scopedSlots:_vm._u([{key:`item.id`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:`item.email`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.email)+" ")]}},{key:`item.activo`,fn:function({ item }){return [_c('v-switch',{key:item.id + 'activo',on:{"change":function($event){return _vm.modificarSwitch(item, 'activo')}},model:{value:(item.activo),callback:function ($$v) {_vm.$set(item, "activo", $$v)},expression:"item.activo"}},[_vm._v(" "+_vm._s(item.activo)+" ")])]}},{key:`item.avisos`,fn:function({ item }){return [_c('v-switch',{key:item.id + 'avisos',attrs:{"color":"primary"},on:{"change":function($event){return _vm.modificarSwitch(item, 'avisos')}},model:{value:(item.avisos),callback:function ($$v) {_vm.$set(item, "avisos", $$v)},expression:"item.avisos"}},[_vm._v(" "+_vm._s(item.avisos)+" ")])]}},{key:`item.publicidad`,fn:function({ item }){return [_c('v-switch',{key:item.id + 'publicidad',on:{"change":function($event){return _vm.modificarSwitch(item, 'publicidad')}},model:{value:(item.publicidad),callback:function ($$v) {_vm.$set(item, "publicidad", $$v)},expression:"item.publicidad"}},[_vm._v(_vm._s(item.publicidad))])]}},{key:`item.principal`,fn:function({ item }){return [_c('v-switch',{key:item.id + 'principal',on:{"change":function($event){return _vm.modificarSwitch(item, 'principal')}},model:{value:(item.principal),callback:function ($$v) {_vm.$set(item, "principal", $$v)},expression:"item.principal"}},[_vm._v(_vm._s(item.principal))])]}},{key:`item.subscripcion_cancelada`,fn:function({ item }){return [_c('span',{class:{
            'texto-verde': !item.subscripcion_cancelada,
            'texto-rojo': item.subscripcion_cancelada,
          }},[_vm._v(" "+_vm._s(!item.subscripcion_cancelada ? "Activa" : "Cancelada"))])]}},{key:`item.fecha_creacion`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatoFecha(item.fecha_creacion))+" ")]}}],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialogCrearCorreo),callback:function ($$v) {_vm.dialogCrearCorreo=$$v},expression:"dialogCrearCorreo"}},[_c('v-card',[_c('v-card-title',{staticClass:"text"},[_vm._v("Crear correo de: "+_vm._s(_vm.item.razon_social))]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.formValido),callback:function ($$v) {_vm.formValido=$$v},expression:"formValido"}},[_c('v-text-field',{attrs:{"outlined":"","rules":[_vm.emailRules],"label":"Correo electrónico","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-checkbox',{attrs:{"label":"Publicidad","required":""},model:{value:(_vm.publicidad),callback:function ($$v) {_vm.publicidad=$$v},expression:"publicidad"}}),_c('v-checkbox',{attrs:{"label":"Avisos","required":""},model:{value:(_vm.avisos),callback:function ($$v) {_vm.avisos=$$v},expression:"avisos"}}),_c('v-checkbox',{attrs:{"label":"Principal","required":""},model:{value:(_vm.principal),callback:function ($$v) {_vm.principal=$$v},expression:"principal"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.cerrar2()}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.crearCorreo()}}},[_vm._v(" Crear ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }