<template>
  <v-container v-if="user" fluid>
    <v-card class="pa-3">
      <v-col>
        <v-card-title>
          Mis Clientes y Rutas
          <v-spacer />
        </v-card-title>
        <v-row>
          <v-col>
            <v-btn
              :class="[
                {
                  'disabled-button': selectedOption != 'misClientes',
                  'enabled-button': selectedOption === 'misClientes',
                },
                'mr-1',
                'ml-2',
                'mb-1',
              ]"
              color="primary"
              @click="selectedOption = 'misClientes'"
            >
              Mis Clientes
            </v-btn>
            <v-btn
              :class="[
                {
                  'disabled-button': selectedOption != 'RutasView',
                  'enabled-button': selectedOption === 'RutasView',
                },
                'mr-1',
                'ml-2',
                'mb-1',
              ]"
              color="primary"
              @click="selectedOption = 'RutasView'"
            >
              Mis Rutas
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-card v-if="selectedOption === 'misClientes'">
        <v-card-title class="teal lighten-3 accent-4 white--text">
          Mis clientes
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div :elevation="0" dark v-bind="attrs" v-on="on" class="mt-2">
                <AgregarCliente />
              </div>
            </template>
            <span>Nuevo Cliente</span>
          </v-tooltip>
          <v-btn class="mt-2" @click="filtrarClientesSinLocalizacion">{{
            clientesFiltrados
              ? "Ver Todos los Clientes"
              : "Ver Clientes sin Localizacion"
          }}</v-btn>
          <p class="ml-3 mt-5 text-center">
            Estas viendo:
            {{
              clientesFiltrados
                ? "Los Clientes sin localizacion" +
                  " (" +
                  itemsFiltrados.length +
                  " de " +
                  items.length +
                  ")"
                : "Todos los clientes" + " (" + items.length + ")"
            }}
          </p>
        </v-card-title>

        <v-row class="mx-2 mt-2">
          <v-col cols="12" md="4">
            <v-select
              :items="dias"
              v-model="searchDia"
              label="Filtrar por día"
            ></v-select>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model="search"
              @input="filtrarItems"
              append-icon="mdi-magnify"
              label="Búsqueda"
              single-line
              hide-details
            ></v-text-field>
          </v-col>

          <v-col v-if="esAdmid || esSupervisor" cols="12" md="4">
            <v-autocomplete
              label="Clientes del usuario"
              v-model="clienteSelected"
              @input="filtrarPorUsuario"
              :items="itemsUsuarios"
              item-text="nombre"
              item-value="id"
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="itemsModificados"
          :loading="loading"
          loading-text="Cargando..."
          no-data-text="No se encontraron clientes"
          no-results-text="No se encontraron clientes"
          :footer-props="{
            'items-per-page-text': 'Clientes por página',
          }"
          :header-props="{
            'sort-by-text': 'Ordenar por',
          }"
        >
          <!-- Columna acciones -->
          <template v-slot:[`item.acciones`]="{ item }">
            <AccionesMisClientes
              :item="item"
              @clienteModificado="clienteModificado"
            />
          </template>
          <!-- Columna activo saux -->
          <template v-slot:[`item.activo_saux`]="{ item }">
            <div class="d-flex justify-center">
              <v-icon
                class="mr-2"
                :color="getColorEstado(item.activo_saux)"
                size="20"
              >
                {{ item.activo_saux ? "mdi-check-circle" : "mdi-close-circle" }}
              </v-icon>
              /
              <v-icon
                class="ml-2"
                :color="getColorEstado(item.activo_adminpaq)"
                size="20"
              >
                {{
                  item.activo_adminpaq ? "mdi-check-circle" : "mdi-close-circle"
                }}
              </v-icon>
            </div>
          </template>
          <!-- Columna latitud/Longitud -->
          <template v-slot:[`item.latitud_longitud`]="{ item }">
            <div class="d-flex justify-center" v-if="item.zona">
              {{ item.zona }}
            </div>
            <div v-else>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="d-flex justify-center"
                    color="warning"
                    >mdi-alert-outline
                  </v-icon>
                </template>
                <span>No hay coordenadas asignadas</span>
              </v-tooltip>
            </div>
          </template>
          <!-- Columna giros -->
          <!-- se necesita comprobar que no este vacio y que no sea null -->
          <template v-slot:[`item.giros`]="{ item }">
            <div v-if="item.giros === null" class="ml-3">Sin giros</div>
            <div v-else-if="item.giros.length === 0" class="ml-3">
              Sin giros
            </div>
            <v-list-group v-else-if="item.giros">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title> Giros </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item v-for="giro in item.giros" :key="giro">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ giro }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <!-- <div v-else>No hay giros</div> -->
          </template>
        </v-data-table>
      </v-card>
      <RutasView v-if="selectedOption === 'RutasView'" />
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import AccionesMisClientes from "@/components/AccionesMisClientes.vue";
import { mapGetters } from "vuex";
import AgregarCliente from "@/components/AgregarCliente.vue";
import RutasView from "./RutasView.vue";

export default {
  data() {
    return {
      selectedOption: "misClientes",
      search: "",
      searchDia: "",
      clientesFiltrados: false,
      items: [],
      itemsTodosClientes: [],
      itemsFiltrados: [],
      itemsUsuarios: [],
      dias: [
        { text: "Todos", value: "" },
        { text: "Lunes", value: "LUNES" },
        { text: "Martes", value: "MARTES" },
        { text: "Miercoles", value: "MIERCOLES" },
        { text: "Jueves", value: "JUEVES" },
        { text: "Viernes", value: "VIERNES" },
        { text: "Sabado", value: "SABADO" },
        { text: "Domingo", value: "DOMINGO" },
      ],
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Código",
          value: "codigo",
        },
        {
          text: "RFC",
          value: "rfc",
        },
        {
          text: "Nombre",
          value: "razon_social",
        },
        {
          text: "Día",
          value: "dia",
        },
        {
          text: "Giros",
          value: "giros",
        },
        {
          text: "Activo saux / Adminpaq",
          value: "activo_saux",
        },
        {
          text: "Acciones",
          value: "acciones",
        },
      ],
      esAdmid: false,
      esSupervisor: false,
      loading: true,
      clienteSelected: "",
      usuarioId: "",
    };
  },
  methods: {
    async filtrarClientesSinLocalizacion() {
      try {
        if (this.clientesFiltrados) {
          this.itemsFiltrados = [...this.items];
        } else {
          this.loading = true;
          const response = await axios.get(
            "/clientes/clientes_sin_localizacion"
          ); // Endpoint que devuelve los IDs

          const idsRequeridos = response.data; // Ejemplo: [2951, 2952, 3414]
          console.log("IDs obtenidos:", idsRequeridos);

          // Filtrar los clientes que coinciden con los IDs obtenidos
          this.itemsFiltrados = this.items.filter((cliente) =>
            idsRequeridos.includes(cliente.id)
          );

          console.log("Clientes filtrados:", this.itemsFiltrados);
        }
        this.clientesFiltrados = !this.clientesFiltrados;
      } catch (error) {
        console.error("Error al filtrar clientes:", error);
      } finally {
        this.loading = false;
      }
    },

    async clienteModificado() {
      if (this.esAdmid) {
        this.clienteSelected = null;
        const response = await axios.get("/usuarios/get_usuarios");
        this.itemsUsuarios = response.data.usuarios;
        // console.log(this.itemsUsuarios);

        const response2 = await axios.get("/clientes/get");
        this.itemsTodosClientes = response2.data;
        this.items = this.itemsTodosClientes;
        this.itemsFiltrados = this.itemsTodosClientes;
      } else {
        //El usuario no es admin, entonces debe obtener el array solo de sus clientes
        const response2 = await axios.get(
          `/clientes/get_mis_clientes?vendedor_id=${this.usuarioId}`
        );
        this.items = response2.data;
        this.itemsFiltrados = this.items;
      }
    },

    async filtrarPorUsuario() {
      if (this.clienteSelected) {
        const response = await axios.get(
          `/clientes/get_mis_clientes?vendedor_id=${this.clienteSelected}`
        );
        this.itemsFiltrados = response.data;
        this.items = response.data;
      } else {
        this.itemsFiltrados = this.itemsTodosClientes;
        this.items = this.itemsTodosClientes;
      }
    },
    filtrarItems() {
      const criterioBusqueda = this.search.toLowerCase();

      this.itemsFiltrados = this.items.filter((item) => {
        // Aplica aquí tu lógica de filtrado según tus necesidades
        for (let prop in item) {
          const valor = item[prop];

          if (
            typeof valor === "string" &&
            valor.toLowerCase().includes(criterioBusqueda)
          ) {
            return true; // Si alguna propiedad coincide, se incluye el item en los resultados
          } else if (
            typeof valor === "number" &&
            valor.toString().toLowerCase().includes(criterioBusqueda)
          ) {
            return true; // Si el valor numérico coincide, se incluye el item en los resultados
          }
        }
        return false;
      });
    },
    async obtenerRol() {
      const response = await axios.get("/usuarios/get_secciones");
      const secciones = response.data.secciones;
      secciones.forEach((element) => {
        if (element === "ADMINISTRADOR") this.esAdmid = true;
        if (element === "supervisor_vendedores") this.esSupervisor = true;
      });
      if (this.esAdmid) {
        //Si el usuario es admin obtiene un array con todos los vendedores para poder visualizar sus clientes
        const response = await axios.get("/usuarios/get_usuarios");
        // this.itemsUsuarios = response.data.usuarios;
        this.itemsUsuarios = response.data.usuarios.filter(
          (usuario) => usuario.nombre_adminpaq
        );
        // console.log(this.itemsUsuarios);

        const response2 = await axios.get("/clientes/get");
        this.itemsTodosClientes = response2.data;
        this.items = this.itemsTodosClientes;
        this.itemsFiltrados = this.itemsTodosClientes;
      } else if (this.esSupervisor) {
        //Si el usuario es supervisor obtiene un array con los vendedores de las sucursales que supervisa para poder visualizar sus clientes
        const response = await axios.get("/usuarios/yo");

        const permisosArray = response.data.permisos.split(" ");
        const municipiosSet = new Set();

        permisosArray.forEach((permiso) => {
          //Primero se obtienen los nombres de las sucursales que supervisa
          const partes = permiso.split(":");
          if (
            partes.length === 3 &&
            partes[1] === "supervisor_vendedores" &&
            partes[2] === "ver"
          ) {
            municipiosSet.add(partes[0]);
          }
        });

        const municipiosConPermisos = Array.from(municipiosSet); //Array con nombres de las sucursales que supervisa

        const response2 = await axios.get("/usuarios/get_usuarios");
        const usuariosVendedores = response2.data.usuarios.filter(
          (usuario) => usuario.nombre_adminpaq
        );
        this.itemsUsuarios = usuariosVendedores.filter(
          (usuario) => municipiosConPermisos.includes(usuario.sucursal) //Usuarios de sucursales que supervisa
        );
        // console.log(this.itemsUsuarios);

        let todosClientes = []; //Array con los clientes de todos los usuarios
        this.itemsUsuarios.forEach(async (usuario) => {
          const response3 = await axios.get(
            `/clientes/get_mis_clientes?vendedor_id=${usuario.id}`
          );
          todosClientes.push(...response3.data);
        });
        // console.log(todosClientes);

        this.itemsTodosClientes = todosClientes;
        this.items = this.itemsTodosClientes;
        this.itemsFiltrados = this.itemsTodosClientes;
      } else {
        //El usuario no es admin ni supervisor, entonces debe obtener el array solo de sus clientes
        const response = await axios.get("/usuarios/yo"); //Se obtienen datos del usuario, en este caso requerimos su id
        this.usuarioId = response.data.id;
        const response2 = await axios.get(
          `/clientes/get_mis_clientes?vendedor_id=${this.usuarioId}`
        );
        this.items = response2.data;
        this.itemsFiltrados = this.items;
      }
      this.loading = false;
    },
    getColor(estado) {
      return estado ? "green" : "warning";
    },
    getColorEstado(estado) {
      return estado ? "green" : "error";
    },
  },
  created() {
    this.obtenerRol();
  },
  components: {
    AccionesMisClientes,
    AgregarCliente,
    RutasView,
  },
  computed: {
    ...mapGetters(["user"]),
    itemsModificados() {
      //clientes modificados para mostrarlos en la tabla
      let itemsModificados = [];
      if (this.searchDia === "") {
        itemsModificados = this.itemsFiltrados.map((cliente) => {
          const nuevoCliente = { ...cliente };
          if (cliente.latitud && cliente.longitud) {
            nuevoCliente.latitud_longitud = true;
          } else {
            nuevoCliente.latitud_longitud = false;
          }
          return nuevoCliente;
        });
      } else {
        itemsModificados = this.itemsFiltrados.filter((cliente) => {
          const nuevoCliente = { ...cliente };
          if (cliente.latitud && cliente.longitud) {
            nuevoCliente.latitud_longitud = true;
          } else {
            nuevoCliente.latitud_longitud = false;
          }
          return cliente.dia === this.searchDia;
        });
      }

      console.log(itemsModificados);
      return itemsModificados;
    },
  },
};
</script>
