<template>
  <v-card>
    <v-btn
      class="absolute top right"
      icon
      color="blue darken-1"
      text
      @click="cerrar()"
    >
      <v-icon dark> mdi-close </v-icon>
    </v-btn>
    <v-card-title>Localizaciones de: {{ item.razon_social }}</v-card-title>
    <v-card-text>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              ><v-btn color="primary" @click="dialogCrearLocalizacion = true"
                >Crear Localizacion</v-btn
              ></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-data-table
        dense
        :headers="headers"
        :items="items"
        :items-per-page="5"
        class="elevation-1"
        no-data-text="No hay elementos para mostrar"
        :footer-props="{
          'items-per-page-text': 'Elementos por página',
        }"
        :header-props="{
          'sort-by-text': 'Ordenar por',
        }"
        :item-class="defaultColor"
      >
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id }}
        </template>

        <template v-slot:[`item.direccion_linea_1`]="{ item }">
          {{ item.direccion_linea_1 }}
        </template>

        <template v-slot:[`item.codigo_postal`]="{ item }">
          {{ item.codigo_postal }}
        </template>

        <template v-slot:[`item.ciudad`]="{ item }">
          {{ item.ciudad }}
        </template>
        <template v-slot:[`item.latitud_longitud`]="{ item }">
          {{ item.latitud + ", " + item.longitud }}
        </template>
        <template v-slot:[`item.descripcion`]="{ item }">
          {{ item.descripcion }}
        </template>
        <template v-slot:[`item.notas`]="{ item }">
          {{ item.notas }}
        </template>
        <template v-slot:[`item.default`]="{ item }">
          {{ item.default === true ? "Si" : "No" }}
        </template>
        <template v-slot:[`item.editar`]="{ item }">
          <v-icon color="blue darken-1" @click="abrirDialogoEdicion(item.id)">
            mdi-pencil
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>

    <!-- Diálogo de creación de localización -->
    <v-dialog v-model="dialogCrearLocalizacion" max-width="600px">
      <v-card>
        <v-card-title class="text"
          >Crear localización de: {{ item.razon_social }}</v-card-title
        >

        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-alert
              v-if="
                !usarMiLocalizacion &&
                !mostrarCoordenadas &&
                !mostrarInputsAdicionales &&
                !mostrarDireccion &&
                !mostrarFiscal &&
                !mostrarMapa
              "
              color="warning"
              outlined
              prominent
              :value="true"
              icon="mdi-alert-outline"
            >
              <b>Por favor, eliga un metodo para obtener la localizacion</b>
            </v-alert>
            <!-- Checboxes para seleccion la opcion de la localización -->
            <v-checkbox
              v-if="mostrarCheckbox('fiscal')"
              label="Obtener por Domicilio Fiscal"
              v-model="mostrarFiscal"
              @change="
                obtenerDomicilioFiscalCliente() && seleccionarOpcion('fiscal')
              "
            >
            </v-checkbox>
            <v-checkbox
              v-if="mostrarCheckbox('direccion')"
              label="Obtener por Dirección"
              v-model="mostrarDireccion"
              @change="seleccionarOpcion('direccion')"
            ></v-checkbox>
            <v-checkbox
              v-if="mostrarCheckbox('coordenadas')"
              label="Obtener por Coordenadas"
              v-model="mostrarCoordenadas"
              @change="seleccionarOpcion('coordenadas')"
            ></v-checkbox>
            <v-checkbox
              v-if="mostrarCheckbox('localizacion')"
              label="Usar mi localización actual"
              v-model="usarMiLocalizacion"
              @change="
                ObtenerUbicacionActual() && seleccionarOpcion('localizacion')
              "
            ></v-checkbox>

            <!-- implementacion de mapa google -->
            <v-checkbox
              v-if="mostrarCheckbox('mapa')"
              label="Obtener por ubicacion en mapa"
              v-model="mostrarMapa"
              @change="seleccionarOpcion('mapa') && abrirMapaGoogle()"
            ></v-checkbox>

            <v-alert
              v-if="mostrarMapa"
              color="warning"
              outlined
              prominent
              :value="true"
              icon="mdi-alert-outline"
            >
              <b
                >Para obtener la ubicacion en el mapa, haga click donde requiera
                saber su ubicacion. Despues aparecera los datos llenos en los
                recuadros de abajo.</b
              >
            </v-alert>

            <gmap-map
              v-if="mostrarMapa"
              :center="mapCenter"
              :zoom="zoom"
              style="width: 100%; height: 400px; margin-bottom: 20px"
              :options="{ gestureHandling: 'greedy' }"
              @click="mapClick"
            >
              <gmap-marker
                :position="markerPosition"
                :clickable="true"
                :draggable="true"
                @dragend="markerDragEnd"
              />
            </gmap-map>

            <!-- Input para la dirección -->
            <v-text-field
              v-if="
                mostrarDireccion ||
                usarMiLocalizacion ||
                mostrarInputsAdicionales ||
                mostrarFiscal
              "
              v-model="line_1"
              @change="obtenerUbicacion"
              label="Direccion 1"
              outlined
              @input="verificarDireccion"
              @keyup.enter="ObtenerUbicacionDireccion()"
              append-icon="mdi-magnify"
              @click:append="ObtenerUbicacionDireccion()"
            ></v-text-field>

            <v-text-field
              v-if="
                mostrarInputsAdicionales || usarMiLocalizacion || mostrarFiscal
              "
              v-model="line_2"
              label="Direccion 2"
              outlined
            ></v-text-field>

            <v-text-field
              v-if="
                mostrarInputsAdicionales || usarMiLocalizacion || mostrarFiscal
              "
              v-model="city"
              label="Ciudad"
              outlined
            >
            </v-text-field>

            <v-text-field
              v-if="
                mostrarInputsAdicionales || usarMiLocalizacion || mostrarFiscal
              "
              v-model="zipcode"
              label="Código Postal"
              outlined
            ></v-text-field>

            <v-text-field
              v-if="
                mostrarCoordenadas ||
                usarMiLocalizacion ||
                mostrarInputsAdicionales ||
                mostrarFiscal
              "
              v-model="coordenadas2"
              @change="obtenerUbicacion"
              label="Latitud y Longitud"
              outlined
              @blur="verificarDireccion2"
              @keyup.enter="ObtenerUbicacionCoordenadas()"
              append-icon="mdi-magnify"
              @click:append="ObtenerUbicacionCoordenadas()"
            ></v-text-field>

            <div
              v-if="
                mostrarInputsAdicionales || usarMiLocalizacion || mostrarFiscal
              "
            >
              <h3 class="pa-2">Descripción:</h3>
              <v-text-field
                v-model="description"
                label="Ej. Veterinaria Los Cachorros."
                outlined
              >
              </v-text-field>
            </div>
            <div
              v-if="
                mostrarInputsAdicionales || usarMiLocalizacion || mostrarFiscal
              "
            >
              <h3 class="pa-2">Notas:</h3>
              <v-text-field v-model="notas" label="Notas adicionales" outlined>
              </v-text-field>
            </div>
            <!-- Checkbox que al marcarlo ponga la el valor de la variable en verdadero -->
            <v-checkbox
              v-if="
                mostrarInputsAdicionales || usarMiLocalizacion || mostrarFiscal
              "
              v-model="default_direccion"
              label="Usar como dirección principal"
            ></v-checkbox>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cerrar2()">Cancelar</v-btn>
          <v-btn color="blue darken-1" text @click="crearLocalizacion()">
            Crear
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Diálogo de verificación -->
    <v-dialog v-model="dialogoVerificacion" max-width="60%" persistent>
      <v-card>
        <v-row>
          <v-col cols="12" class="pa-6 align-center">
            <v-alert
              color="success"
              icon="mdi-check"
              outlined
              prominent
              :value="true"
            >
              Se llenaron todos los datos lo mejor posible, Favor de verificar
              los datos antes de crear la Localización e ingresar una
              Descripcion.
            </v-alert>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogoVerificacion = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Diálogo para Editar Localización -->
    <v-dialog v-model="dialogoEdicion" max-width="600px">
      <v-card>
        <v-card-title>
          Editar Localización: {{ this.localizacionActual.id }}
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="formValido">
            <v-text-field
              outlined
              v-model="localizacionActual.direccion_linea_1"
              label="Dirección 1"
            ></v-text-field>
            <v-text-field
              outlined
              v-model="localizacionActual.direccion_linea_2"
              label="Dirección 2"
            ></v-text-field>
            <v-text-field
              outlined
              v-model="localizacionActual.ciudad"
              label="Ciudad"
            ></v-text-field>
            <v-text-field
              outlined
              v-model="localizacionActual.codigo_postal"
              label="Código Postal"
            ></v-text-field>
            <v-text-field
              outlined
              v-model="localizacionActual.latitud_longitud"
              label="Latitud y Longitud"
            ></v-text-field>
            <v-text-field
              outlined
              v-model="localizacionActual.descripcion"
              label="Descripción"
            ></v-text-field>
            <v-text-field
              outlined
              v-model="localizacionActual.notas"
              label="Notas"
            ></v-text-field>
            <v-checkbox
              v-model="localizacionActual.default"
              label="Usar como direccion principal"
            ></v-checkbox>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialogoEdicion = false"
            >Cancelar</v-btn
          >
          <v-btn color="green darken-1" text @click="guardarCambios()"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: "FacturaPendiente",
  props: ["item"],
  data() {
    return {
      mostrarMapa: false,
      mapCenter: { lat: 32.62340014186072, lng: -115.46240380788693 },
      zoom: 12,
      markerPosition: null,
      direccionObtenida: false, // flag que indica si ya obtuvo la direccion
      dialogoEdicion: false,
      localizacionActual: {},
      formValido: false,
      dialogoVerificacion: false,
      mostrarFiscal: false,
      selected: null,
      confirmacionPendiente: true,
      mostrarDireccion: false,
      mostrarInputsAdicionales: false,
      mostrarCoordenadas: false,
      dialogCrearLocalizacion: false,
      valid: false,
      usarDomicilioFiscal: false,
      direccion: "",
      usarMiLocalizacion: false,
      latitud: "",
      longitud: "",
      coordenadas2: "",
      default_direccion: true,
      line_1: "",
      line_2: "",
      zipcode: "",
      description: "",
      city: "",
      notas: "",
      items: [],
      estadoCredito: {},
      saldo_pendiente: 0,
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Direccion",
          value: "direccion_linea_1",
        },
        {
          text: "Codigo Postal",
          value: "codigo_postal",
          // sortable: false,
        },
        {
          text: "Ciudad",
          value: "ciudad",
          // sortable: false,
        },
        {
          text: "Latitud y Longitud",
          value: "latitud_longitud",
        },
        {
          text: "Descripcion",
          value: "descripcion",
        },
        {
          text: "Notas",
          value: "notas",
        },
        {
          text: "Principal",
          value: "default",
        },
        {
          text: "Editar",
          value: "editar",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    //IMPLEMENTACION DE MAPA DE GOOGLE

    async abrirMapaGoogle() {
      this.mostrarMapa = !this.mostrarMapa;
    },

    setUserLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            this.mapCenter = { lat: latitude, lng: longitude };
            this.markerPosition = { lat: latitude, lng: longitude }; // Opcional: mover marcador al centro
          },
          (error) => {
            console.error("Error al obtener la ubicación del usuario:", error);
            alert(
              "No se pudo obtener tu ubicación. El mapa usará un centro predeterminado."
            );
          }
        );
      } else {
        console.error(
          "La geolocalización no es compatible con este navegador."
        );
        alert("Tu navegador no soporta geolocalización.");
      }
    },

    async mapClick(event) {
      // Coloca el marcador en la posición del clic en el mapa
      this.markerPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.latitud = this.markerPosition.lat;
      this.longitud = this.markerPosition.lng;
      this.ObtenerUbicacionCoordenadas();
      this.mostrarInputsAdicionales = true;
    },

    markerDragEnd(event) {
      //Actualiza la posición del marcador cuando se arrastra
      this.markerPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.latitud = this.markerPosition.lat;
      this.longitud = this.markerPosition.lng;
      this.ObtenerUbicacionCoordenadas();
    },

    async abrirDialogoEdicion(idLocalizacion) {
      try {
        const response = await axios.get(
          `/clientes/get_localizaciones?id_cliente=${this.item.id}`
        );

        if (response.status === 200) {
          const localizacion = response.data.find(
            (loc) => loc.id === idLocalizacion
          );

          if (localizacion) {
            this.localizacionActual = {
              ...localizacion,
              latitud_longitud: `${localizacion.latitud}, ${localizacion.longitud}`,
            }; // Clonar los datos para la edición
            this.dialogoEdicion = true; // Abrir el diálogo de edición
          } else {
            console.error("Localización no encontrada");
          }
        }
      } catch (error) {
        console.error("Error al obtener las localizaciones:", error);
      }
    },

    async guardarCambios() {
      const [latitud, longitud] = this.localizacionActual.latitud_longitud
        .split(",")
        .map((coord) => coord.trim());

      const nuevaDireccion = {
        id: this.localizacionActual.id,
        direccion_linea_1: this.localizacionActual.direccion_linea_1,
        direccion_linea_2: this.localizacionActual.direccion_linea_2,
        ciudad: this.localizacionActual.ciudad,
        codigo_postal: this.localizacionActual.codigo_postal,
        latitud: latitud,
        longitud: longitud,
        descripcion: this.localizacionActual.descripcion,
        notas: this.localizacionActual.notas,
        default_direccion: this.localizacionActual.default,
      };
      try {
        const payload = {
          id: nuevaDireccion.id,
          latitud: nuevaDireccion.latitud,
          longitud: nuevaDireccion.longitud,
          descripcion: nuevaDireccion.descripcion,
          notas: nuevaDireccion.notas,
          default: nuevaDireccion.default_direccion,
          direccion_linea_1: nuevaDireccion.direccion_linea_1,
          direccion_linea_2: nuevaDireccion.direccion_linea_2,
          codigo_postal: nuevaDireccion.codigo_postal,
          ciudad: nuevaDireccion.ciudad,
        };
        const response = await axios.put(
          `/clientes/modificar_localizacion`,
          payload
        );
        if (response.status === 200) {
          this.dialogoEdicion = false;
          await this.getLocalizaciones();
        }
        console.log("Direccion modificada con exito:", response.data);
      } catch (error) {
        console.error("Error al modificar la localización:", error);
      }
    },

    defaultColor(item) {
      return item.default === true ? "fila-verde" : "";
    },

    seleccionarOpcion(opcion) {
      if (this.selected === opcion) {
        this.selected = null;
        this.mostrarDireccion = false;
        this.mostrarCoordenadas = false;
        this.usarMiLocalizacion = false;
        this.mostrarFiscal = false;
        this.mostrarMapa = false;
      } else {
        this.selected = opcion;
        this.mostrarDireccion = opcion === "direccion";
        this.mostrarCoordenadas = opcion === "coordenadas";
        this.usarMiLocalizacion = opcion === "localizacion";
        this.mostrarFiscal = opcion === "fiscal";
        this.mostrarMapa = opcion === "mapa";
      }
    },

    mostrarCheckbox(opcion) {
      //si se quita la seleccion del checkbox se limpian los campos
      if (!this.selected) {
        this.line_1 = "";
        this.line_2 = "";
        this.zipcode = "";
        this.city = "";
        this.coordenadas2 = "";
        this.description = "";
        this.notas = "";
        this.latitud = "";
        this.longitud = "";
        this.mostrarInputsAdicionales = false;
      }
      return !this.selected || this.selected === opcion;
    },

    verificarDireccion() {
      this.mostrarInputsAdicionales = this.line_1.trim() !== "";
      /*limpiar los campos al tener cambios en el input
      this.line_2 = "";
      this.zipcode = "";
      this.city = "";
      this.coordenadas2 = "";*/
    },

    verificarDireccion2() {
      const [lat, lon] = (this.mostrarInputsAdicionales = this.coordenadas2
        .split(",")
        .map((coord) => coord.trim()));

      // Verifica si ambas coordenadas son válidas
      if (!isNaN(lat) && !isNaN(lon)) {
        this.latitud = lat;
        this.longitud = lon;
      } else {
        console.error("Formato de coordenadas inválido");
      }
      /*limpiar los campos al tener cambios en el input
      this.line_1 = "";
      this.line_2 = "";
      this.zipcode = "";
      this.city = "";*/
    },

    cerrar() {
      this.$emit("cerrarDialog", (this.dialog = false));
    },

    cerrar2() {
      this.dialogCrearLocalizacion = false;
    },

    async getLocalizaciones() {
      try {
        const response = await axios.get(
          `/clientes/get_localizaciones?id_cliente=${this.item.id}`
        );
        this.items = response.data;
        console.log(response.data);
      } catch (error) {
        // alert(`Error: ${JSON.stringify(error.response.data.detail)}`);
        console.log(error.response.data);
      }
    },

    async crearLocalizacion() {
      /*Mostrar una alerta si no estan llenos todos los campos y cuando esten llenos se crea la localizacion*/
      if (
        this.line_1.trim() === "" ||
        //this.line_2.trim() === "" ||
        this.zipcode.trim() === "" ||
        this.city.trim() === "" ||
        this.coordenadas2.trim() === "" ||
        this.description.trim() === ""
      ) {
        alert(
          "Los campos: Dirección, Código Postal, Ciudad, Coordenadas y Descripción son obligatorios."
        );
        return;
      }

      const [latitud, longitud] = this.coordenadas2
        .split(",")
        .map((coord) => coord.trim());

      try {
        const data = {
          latitud: latitud,
          longitud: longitud,
          cliente: this.item.id,
          descripcion: this.description,
          notas: this.notas,
          direccion_linea_1: this.line_1,
          direccion_linea_2: this.line_2,
          codigo_postal: this.zipcode,
          ciudad: this.city,
          default: this.default_direccion,
        };

        const response = await axios.post(`/clientes/crear_localizacion`, data);
        console.log(response.data);

        this.items.push(response.data);
        await this.getLocalizaciones();
        this.resetForm(); // Resetear formulario después de crear localización
        this.dialogCrearLocalizacion = false;
      } catch (error) {
        if (error.response && error.response.status === 409) {
          alert(
            `${error.response.data}. ` +
              " Favor de colocar una ubicación de al menos 10 metros de distancia para ese cliente."
          );
          return;
        } else console.log(error.response.data);
      }
    },

    resetForm() {
      this.coordenadas2 = "";
      this.latitud = "";
      this.longitud = "";
      this.line_1 = "";
      this.line_2 = "";
      this.zipcode = "";
      this.description = "";
      this.notas = "";
      this.city = "";
    },

    async obtenerUbicacion() {
      if (!this.direccionObtenida) {
        if (this.line_1) {
          await this.ObtenerUbicacionDireccion();
        } else if (this.coordenadas2) {
          await this.ObtenerUbicacionCoordenadas();
        }
      } else {
        console.log("La direccion ya fue obtenida, editala");
      }
      /*if (this.usarMiLocalizacion) {
       
      } else if (this.latitud && this.longitud) {
        
      } else if (this.line_1) {
        
      }*/
    },

    async ObtenerUbicacionActual() {
      // Configuración avanzada de Geolocalización para obtener mayor precisión
      const opciones = {
        enableHighAccuracy: true, // Prioriza la precisión (más lento pero más exacto)
        timeout: 10000, // Tiempo máximo en milisegundos para obtener la ubicación
        maximumAge: 0, // No usar caché, siempre buscar nueva ubicación
      };
      // Obtener la ubicación actual del usuario
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          this.coordenadas2 = `${latitude},${longitude}`;

          // Llamada a la API para obtener la dirección desde las coordenadas
          if (this.usarMiLocalizacion) {
            try {
              /*const previousAuthorization =
                axios.defaults.headers.common["Authorization"];
              delete axios.defaults.headers.common["Authorization"];*/

              const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCrww1bKVlSS49SdrvvlCLQmDgiNlzd644`,
                {
                  headers: {
                    Authorization: undefined,
                  },
                }
              );
              /*axios.defaults.headers.common["Authorization"] =
                previousAuthorization;*/
              console.log("Resultados de la API:", response.data);

              if (response.data.results.length === 0) {
                alert("No se pudo obtener la ubicación");
                return;
              }

              const address = response.data.results[0];
              this.rellenarCamposConDireccion(address);
              //this.direccionObtenida = true; //Marcar que ya se obtuvo la direccion
              this.dialogoVerificacion = true;
            } catch (error) {
              console.error("Error al obtener la dirección:", error);
            }
          }
        },

        (error) => {
          console.error("Error al obtener la ubicación:", error);
        },
        opciones
      );
    },

    async ObtenerUbicacionDireccion() {
      console.log("Direccion Ingresada:", this.line_1);
      // Llamada a la API para obtener la dirección desde las coordenadas
      try {
        /*const previousAuthorization =
            axios.defaults.headers.common["Authorization"];
          delete axios.defaults.headers.common["Authorization"];*/

        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${this.line_1}&key=AIzaSyCrww1bKVlSS49SdrvvlCLQmDgiNlzd644`,
          {
            headers: {
              Authorization: undefined,
            },
          }
        );
        /*axios.defaults.headers.common["Authorization"] =
            previousAuthorization;*/
        console.log("Resultados de la API:", response.data);

        if (response.data.results.length === 0) {
          alert("No se pudo obtener la ubicación");
          return;
        }

        const address = response.data.results[0];
        this.latitud = address.geometry.location.lat;
        this.longitud = address.geometry.location.lng;

        this.coordenadas2 = `${this.latitud},${this.longitud}`;

        this.rellenarCamposConDireccion(address);
        this.direccionObtenida = true; //Marcar que ya se obtuvo la direccion
        this.dialogoVerificacion = true;
      } catch (error) {
        console.error("Error al obtener la dirección:", error);
      }
    },

    async ObtenerUbicacionCoordenadas() {
      console.log("Metodo ObtenerUbicacionCoordenadas llamado");
      // Llamada a la API para obtener la dirección desde las coordenadas
      if (this.latitud && this.longitud) {
        this.coordenadas2 = `${this.latitud},${this.longitud}`;
      }

      if (this.coordenadas2) {
        try {
          /*const previousAuthorization =
            axios.defaults.headers.common["Authorization"];
          delete axios.defaults.headers.common["Authorization"];*/

          const response = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.latitud},${this.longitud}&key=AIzaSyCrww1bKVlSS49SdrvvlCLQmDgiNlzd644`,
            {
              headers: {
                Authorization: undefined,
              },
            }
          );
          /*axios.defaults.headers.common["Authorization"] =
            previousAuthorization;*/
          console.log("Resultados de la API:", response.data);

          if (response.data.results.length === 0) {
            alert("No se pudo obtener la ubicación");
            return;
          }

          const address = response.data.results[0];
          this.rellenarCamposConDireccion(address);
          this.direccionObtenida = true; //Marcar que ya se obtuvo la direccion
          this.dialogoVerificacion = true;
        } catch (error) {
          console.error("Error al obtener la dirección:", error);
        }
      }
    },
    rellenarCamposConDireccion(address) {
      const components = address.address_components;

      // Buscar calle y número
      const street = components.find((c) =>
        c.types.includes("route")
      )?.long_name;
      const streetNumber = components.find((c) =>
        c.types.includes("street_number")
      )?.long_name;

      //Buscar el long_name de sublocalidad
      const long_name = components.find((c) =>
        c.types.includes("sublocality")
      )?.long_name;

      // Buscar ciudad y abreviación del estado
      const city = components.find((c) =>
        c.types.includes("locality")
      )?.long_name;
      const stateShort = components.find((c) =>
        c.types.includes("administrative_area_level_1")
      )?.short_name; // Usamos la abreviación del estado

      const zipcode = components.find((c) =>
        c.types.includes("postal_code")
      )?.long_name;

      // Asignar valores a los campos del formulario
      this.line_1 =
        street && streetNumber ? `${street} ${streetNumber}` : street || "";
      this.city = city && stateShort ? `${city}, ${stateShort}` : city || "";
      this.line_2 = long_name || ""; // Dejar vacío este campo
      this.zipcode = zipcode || "";
    },

    async obtenerDomicilioFiscalCliente() {
      if (this.mostrarFiscal) {
        try {
          // Llamada a tu backend para obtener el domicilio fiscal
          const response = await axios.get(
            `/clientes/get_domicilio_fiscal?id_cliente=${this.item.id}`
          );
          const domicilio = response.data;

          // Construir la dirección completa para la API de Google Maps
          const direccionCompleta = `${domicilio.calle} ${domicilio.numero}, ${domicilio.colonia}, ${domicilio.ciudad}, ${domicilio.estado}, ${domicilio.codigo_postal}`;

          console.log("Domicilio fiscal recibido:", direccionCompleta);

          /*const previousAuthorization =
            axios.defaults.headers.common["Authorization"];
          delete axios.defaults.headers.common["Authorization"];*/
          // Llamada a la API de Google Maps para obtener coordenadas
          const googleResponse = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${direccionCompleta}&key=AIzaSyCrww1bKVlSS49SdrvvlCLQmDgiNlzd644`,
            {
              headers: {
                Authorization: undefined,
              },
            }
          );

          /*axios.defaults.headers.common["Authorization"] =
            previousAuthorization;*/

          if (googleResponse.data.results.length === 0) {
            alert(
              "No se pudo obtener la ubicación a partir del domicilio fiscal"
            );
            return;
          }

          const address = googleResponse.data.results[0];
          this.rellenarCamposConDireccion(address);
          // Asignar coordenadas obtenidas
          const { lat, lng } = address.geometry.location;
          this.coordenadas2 = `${lat},${lng}`;

          this.dialogoVerificacion = true;
        } catch (error) {
          console.error(
            "Error al obtener el domicilio fiscal o la geolocalización:",
            error
          );
        }
      }
    },
  },
  computed: {},
  async created() {
    this.getLocalizaciones();
  },
  mounted() {
    this.obtenerUbicacion();
    this.setUserLocation();
  },
  watch: {
    latitud(newVal) {
      if (newVal && this.latitud) {
        this.obtenerUbicacion(newVal, this.latitud);
      }
    },
    longitud(newVal) {
      if (newVal && this.longitud) {
        this.obtenerUbicacion(newVal, this.longitud);
      }
    },
  },
};
</script>

<style>
.close-btn {
  float: right;
}

.absolute {
  position: absolute;
}

.top {
  top: 10;
}

.right {
  right: 10;
}
.fila-verde {
  background-color: rgba(43, 129, 214, 0.3);
}

#mapa {
  width: 100%;
  height: 400px;
}
</style>
